import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Import Angular Material modules
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTableModule } from '@angular/material/table'; 
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort'; 
import {MatSelectModule} from '@angular/material/select';
import {MatInputModule} from '@angular/material/input';
import { MatOptionModule } from '@angular/material/core'; 
import { MatDialogModule } from '@angular/material/dialog';
import {MatRadioModule} from '@angular/material/radio';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {LayoutModule} from '@angular/cdk/layout';
import {  MatSidenavModule } from "@angular/material/sidenav";
import {MatListModule} from '@angular/material/list';

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    MatCardModule,
    MatToolbarModule,
    MatIconModule,
    MatFormFieldModule,
    MatTooltipModule,
    MatTableModule, 
    MatPaginatorModule, 
    MatSortModule,
    MatSelectModule,
    MatInputModule,
    MatOptionModule,
    MatDialogModule,
    MatRadioModule    ,
    SlickCarouselModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    MatSidenavModule,
    MatListModule,
    MatIconModule,
  ],
  
  exports: [
    MatButtonModule,
    MatCardModule,
    MatToolbarModule,
    MatIconModule,
    MatFormFieldModule,
    MatTooltipModule,
    MatTableModule, 
    MatPaginatorModule, 
    MatSortModule,
    MatSelectModule,
    MatInputModule,
    MatOptionModule,
    MatDialogModule,
    MatRadioModule,
    SlickCarouselModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    MatListModule,
    MatIconModule,
    MatSidenavModule,
  
  ]
})

export class NgMaterialModule { }
