import camelcaseKeys from "camelcase-keys";
import snakecaseKeys from 'snakecase-keys';

export abstract class BaseModel {

    constructor(data: any) { 
        Object.assign(this, data);
    }

    static assignValues(data: any): any {
        Object.assign(this, data);
        return this;
    }

    static camelizeKeys(data: any): any {
        return camelcaseKeys(data);
    }

    static snakeKeys(data: any): any {
        return snakecaseKeys(data);
    }

    static renameKey(data: any, oldKey:string, newKey:string) {
        Object.defineProperty(data, newKey, Object.getOwnPropertyDescriptor(data, oldKey)!);
        delete data[oldKey];
    }

    toJson(): string {
        return JSON.stringify(this);
    }
    
}
