
import { Injectable } from '@angular/core';
import Swal, { SweetAlertIcon } from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})


export class SwalService {

  constructor() {}

  showSwal(
    config: any,
    onConfirm?: () => void
  ) {
    const defaultConfig: any = {
      icon: 'info' as SweetAlertIcon,
      title: '',
      text: '',
      showCancelButton: false,
      cancelButtonText: 'Cancel',
      showConfirmButton: true,
      confirmButtonText: 'Confirm',
      ...config
    };

    return Swal.fire(defaultConfig).then(result => {
      if (result.isConfirmed && onConfirm) {
        onConfirm();
      }
      return result;
    });
  }
  
}

