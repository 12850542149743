import { Injectable } from "@angular/core";
import {  MatSnackBar,  MatSnackBarHorizontalPosition,  MatSnackBarVerticalPosition} from '@angular/material/snack-bar';

@Injectable({
  providedIn: "root",
})

export class SnackBarService {
  horizontalPosition: MatSnackBarHorizontalPosition = 'end';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  timeOut = 2000;
  
  constructor(private _snackBar: MatSnackBar) {}

  openErrorSnackBar(message: any) {
    console.log('openSnackBar(message) : ', message);
    this._snackBar.open(message, 'close', {
      duration: this.timeOut,
      verticalPosition: this.verticalPosition, 
      horizontalPosition: this.horizontalPosition, 
      panelClass: ['snackbar-error'],
    });
  }

  openWarningSnackBar(message: any) {
    console.log('openSnackBar(message) : ', message);
    this._snackBar.open(message, 'close', {
      duration: this.timeOut,
      verticalPosition: this.verticalPosition, 
      horizontalPosition: this.horizontalPosition, 
      panelClass: ['snackbar-warning'],
    });
  }

  openSuccessSnackBar(message: any) {
    console.log('openSnackBar(message) : ', message);
    this._snackBar.open(message, 'close', {
      duration: 1000,
      verticalPosition: this.verticalPosition, 
      horizontalPosition: this.horizontalPosition, 
      panelClass: ['snackbar-success'],
    });
  }
}
